import Filter from "./Filter";
import Meta from "../../components/Meta";

const IllustrationsPage = () => {
  return (
    <>
      <Meta />
      <Filter />
    </>
  );
};
export default IllustrationsPage;
