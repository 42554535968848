import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '../slices/reduxHooks';

const PrivateRoute = () => {
	const { userInfo } = useAppSelector((state) => state.auth);

	return userInfo && userInfo.isAdmin ? (
		<Outlet />
	) : (
		<Navigate to='/login' replace />
	);
};
export default PrivateRoute;
